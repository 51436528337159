import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layouts/content.js";
import Aide from 'src/components/aides.js';
import SEO from 'src/components/seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Questions fréquentes" description="Questions fréquemment posées concernant l'activité de bébés nageurs de l'association l'enfant d'eau" mdxType="SEO" />
    <h2>{`Questions fréquentes`}</h2>
    <Aide mdxType="Aide" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      