import React from 'react';

import * as Theme from 'src/components/layouts/content/theme';
import * as DesignSystem from 'src/components/design-system';

import faqs from 'src/../content/faq.yaml';
import Faq from 'src/components/faq';
import { useLocation } from '@reach/router';

const Aides = () => {
  const location = useLocation();
  const titles = faqs.map((s) => (
    <li className="list-disc list-inside mt-1" key={s.title}>
      <DesignSystem.Link
        to={`${location.pathname}#${s.title.replace(' ', '-')}`}
      >
        {s.title}
      </DesignSystem.Link>
    </li>
  ));
  return (
    <>
      <DesignSystem.P className="mb-4">
        Les réponses à ces questions sont tirées du{' '}
        <DesignSystem.Link href="/reglementinterieurv2.3.pdf" newTab>
          règlement intérieur
        </DesignSystem.Link>{' '}
        et du{' '}
        <DesignSystem.Link href="/guide-parents-juillet-2020.pdf" newTab>
          guide des parents
        </DesignSystem.Link>
        .<br />
        Elles sont organisées par thématique : <ul>{titles}</ul>
      </DesignSystem.P>
      {faqs.map((section) => (
        <>
          <DesignSystem.H3
            className="text-2xl"
            id={section.title.replace(' ', '-')}
          >
            {section.title}
          </DesignSystem.H3>
          <Faq faqs={section.questions} />
        </>
      ))}
    </>
  );
};

export default Aides;
