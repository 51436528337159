import React from 'react';
import classnames from 'classnames';

import Markdown from 'src/components/design-system/markdown';

import Line from './line';

const FAQ = ({ faqs = [] }) => (
  <div className="mb-8">
    <dl>
      {faqs.map((qa, i) => {
        return (
          <Line
            question={qa.question}
            className={classnames({
              'border-t': i > 0,
              'border-t-2': i === 0,
            })}
          >
            <Markdown>{qa.answer}</Markdown>
          </Line>
        );
      })}
    </dl>
  </div>
);

export default FAQ;
