import React, { useState } from 'react';
import classnames from 'classnames';

const Line = ({ question, className, children, ...otherProps }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className={classnames('mt-6 border-gray-200 pt-6', className)}
      {...otherProps}
    >
      <dt className="text-lg leading-7">
        {/* Expand/collapse question button */}
        <button
          className="text-left w-full flex justify-between items-start text-gray-400 focus:outline-none focus:text-gray-900"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="font-medium text-gray-900">{question}</span>
          <span className="ml-6 h-7 flex items-center">
            <svg
              className={classnames('h-6 w-6 transform', {
                '-rotate-180': isOpen,
                'rotate-0': !isOpen,
              })}
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </span>
        </button>
      </dt>
      <dd
        className={classnames('mt-2 pr-12', { hidden: !isOpen, block: isOpen })}
      >
        <p className="text-base leading-6 text-gray-500">{children}</p>
      </dd>
    </div>
  );
};

export default Line;
